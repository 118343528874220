<template>
    <div class="contas-receber">
        <div class="acoesTopo">             
            <b-button variant="danger" class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')" v-b-tooltip.hover.bottomleft="'Cancelar'"
                @click="reset">
                    <i class="fa fa-times button-icons-acoes"></i>
            </b-button>
            <b-button variant="danger" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Excluir'"
                @click="desejaExcluirFatura">
                    <i class="fa fa-trash button-icons-acoes"></i>
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" v-b-tooltip.hover.bottomleft="'Salvar'"
                @click="save">
                    <i class="fa fa-check button-icons-acoes"></i>
            </b-button>                        
            <b-button variant="success" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Opções de Faturas'"
                @click="opcoesFaturas">
                    <i class="fa fa-cogs button-icons-acoes"></i>
            </b-button> 
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Relatórios'"
                @click="loadRelFatura()">
                    <i class="fa fa-file-text-o button-icons-acoes"></i>
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Nova Fatura'"
                @click="novaFatura">
                    <i class="fa fa-plus button-icons-acoes"></i>
            </b-button> 
        </div>

        <b-modal id="modal-remove-fatura" title="Deletar Serviço" v-model="modalRemFatura" >
            <p class="my-4">Deseja realmente excluir as faturas selecionadas?</p>
            <template #modal-footer>
                <div class="w-100">                    
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right ml-3"
                        @click="remove()"                            
                    >
                        Sim
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="$bvModal.hide('modal-remove-fatura')"
                    >
                        Não
                    </b-button>
                </div>
            </template>

        </b-modal> 
        
        <b-card class="cadastros-card-pesquisa"  v-if="show && !gerarFatura && !id_lanc">
            <b-row >                

                <b-col md="4" sm="12" class="radio-consulta">
                    <b-form-group label-for="localizar">
                        <p class="entre-datas">Nome, Docto, CPF ou CNPJ</p> 
                        <b-form-input id="localizar" type="search"
                            size="sm"
                            :formatter="upperCase"
                            v-model="consulta.parcial"
                            style="height: 35px !important;"
                            placeholder="Informe Nome, Docto, CPF ou CNPJ"
                        />
                    </b-form-group> 

                    <b-form-group label-for="conta-filial-sel">
                        <p class="entre-datas">Filial</p> 
                        <b-form-select size="sm" id="conta-filial-sel" v-model="consulta.filial" :options="filiais"/>
                    </b-form-group>                                        

                </b-col>  

                <b-col md="3" sm="12" class="radio-consulta">
                    <b-form-group>
                        <p class="entre-datas">Competência</p> 
                        <datepicker
                            style="width: 100%;"
                            v-model="intervaloComp"
                            type="date"
                            range 
                            format="DD/MM/YYYY"   
                            placeholder="Selecionar intervalo de datas"
                        ></datepicker>
                    </b-form-group>

                    <b-form-group label-for="conta-filial-sel">
                        <p class="entre-datas">Receita</p> 
                        <b-form-select size="sm" id="conta-filial-sel" v-model="consulta.idPlanoConta" :options="planoContas"/>
                    </b-form-group> 
                </b-col>

                <b-col md="3" sm="12" class="radio-consulta">
                    <b-form-group>
                        <p class="entre-datas">Vencimento</p> 
                        <datepicker
                            style="width: 100%;"
                            v-model="intervaloVenc"
                            type="date"
                            range 
                            format="DD/MM/YYYY"   
                            placeholder="Selecionar intervalo de datas"
                        ></datepicker>
                    </b-form-group>

                    <b-form-checkbox-group
                        style="margin-top: 5px"
                        id="checkbox-status"
                        v-model="consulta.status"
                        :options="consulta.opcoes"
                        name="chk-status"
                    ></b-form-checkbox-group>  
                </b-col>

                <b-col md="2" sm="12" class="radio-consulta d-flex justify-content-end">
                    <b-button 
                        class="botao-consulta" 
                        variant="outline-info" 
                        size="lg"
                        @click="novaConsulta"
                    >
                        <i class="fa fa-search button-icons"></i>
                    </b-button>
                </b-col>               

            </b-row>

        </b-card>       

        <b-card class="cadastros-card-corpo" v-show="!show || gerarFatura || id_lanc" :style="gerarFatura ? 'padding: 0; margin: 10px -15px -15px -15px': ''">
            <b-form>
                <input id="conta-receber-id" type="hidden" v-model="contaReceber.id_conta_receber" />

                <b-row v-if="!gerarFatura">
                    <b-col md="2" sm="12">
                        <b-form-group label="Fatura" label-for="num-conta-receber">
                            <b-form-input id="num-conta-receber" type="text"
                                v-model="contaReceber.num_fatura" 
                                readonly/>
                        </b-form-group>
                    </b-col>  
                    <b-col md="7" sm="12">    
                        <b-form-group label="Pessoa" label-for="conta-receber-pessoa" v-if="mode !== 'remove' && !contaReceber.id_contrato">
                            <Autocomplete
                                id="conta-receber-pessoa"
                                :options="pessoas"
                                label-key="razao_social"
                                value-key="idpessoa"
                                v-model="contaReceber.id_pessoa"
                                placeholder=""
                                @shouldSearch="loadPessoas"
                                @select="onSelect"
                               
                            />
                        </b-form-group>
                        <b-form-group label="Pessoa" label-for="conta-receber-pessoa-2" v-if="mode === 'remove' || contaReceber.id_contrato > 0">
                            <b-form-input id="conta-receber-pessoa-2" type="text"                                
                                :formatter="upperCase"
                                v-model="contaReceber.razao_social" required
                                readonly />
                        </b-form-group>
                       
                    </b-col> 

                    <b-col md="3" sm="12">
                        <b-form-group label="Valor" label-for="valor-conta-receber-m">
                            <money v-model="contaReceber.valor" v-bind="money" class="form-control" :readonly="mode === 'remove' || contaReceber.id_contrato > 0"/>
                        </b-form-group>
                    </b-col> 
                </b-row>

                <b-row v-if="!gerarFatura">
                    
                    <b-col md="4" sm="12">
                        <b-form-group label="Data Docto" label-for="data-docto-conta-receber">
                            <b-form-input id="data-docto-conta-receber" type="date"
                                v-model="contaReceber.data_documento"
                                :readonly="mode === 'remove' || contaReceber.id_contrato > 0" required/>
                        </b-form-group>
                    </b-col>  
                    <b-col md="4" sm="12">
                        <b-form-group label="Nº Docto" label-for="num-docto-conta-receber">
                            <b-form-input id="num-docto-conta-receber" type="text"
                                v-mask="mascaras.docto"
                                :formatter="upperCase"
                                v-model="contaReceber.num_docto" required
                                :readonly="mode === 'remove' || contaReceber.id_contrato > 0"/>
                        </b-form-group>
                    </b-col>                   
                </b-row>

                <b-row v-if="!gerarFatura">                     
                    <b-col md="12" sm="12">
                        <b-form-group label="Descrição" label-for="descricao-conta-receber">
                            <b-form-textarea
                                :state="limiteCaracteres(contaReceber.descricao, 200)"
                                id="descricao-conta-receber"
                                placeholder="Informe uma descrição de até 200 caracteres..."
                                v-model="contaReceber.descricao"
                                rows="3"
                                :readonly="mode === 'remove' || contaReceber.id_contrato > 0"
                            ></b-form-textarea>
                            <span class="frase-erro" v-if="!limiteCaracteres(contaReceber.descricao, 200)" >Excedeu a quantidade máxima de caracteres</span>
                        </b-form-group>
                    </b-col> 
                </b-row>

                <b-card header="Fiscal" header-bg-variant="info" header-text-variant="white" v-if="!gerarFatura && false">                    
                    <b-row>
                        <b-col md="3" sm="12">
                            <b-form-group label="Nº Docto" label-for="num-docto-conta-receber">
                                <b-form-input id="num-docto-conta-receber" type="text"
                                    v-mask="mascaras.docto"
                                    :formatter="upperCase"
                                    v-model="contaReceber.num_docto" required
                                    :readonly="mode === 'remove' || contaReceber.id_contrato > 0"/>
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-form-group label="Série" label-for="serie-conta-receber">
                                <b-form-input id="serie-conta-receber" type="text"
                                    v-mask="mascaras.serie"
                                    :formatter="upperCase"
                                    v-model="contaReceber.serie" required
                                    :readonly="mode === 'remove' || contaReceber.id_contrato > 0"/>
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-form-group label="Modelo" label-for="modelo-conta-receber">                                
                                <b-form-input id="modelo-conta-receber" type="text"
                                    v-mask="mascaras.mod"
                                    :formatter="upperCase"
                                    v-model="contaReceber.modelo" required
                                    :readonly="mode === 'remove' || contaReceber.id_contrato > 0"/>
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-form-group label="CFOP" label-for="cfop-conta-receber">
                                <b-form-input id="cfop-conta-receber" type="text"
                                    v-mask="mascaras.cfop"
                                    :formatter="upperCase"
                                    v-model="contaReceber.cfop" required
                                    :readonly="mode === 'remove' || contaReceber.id_contrato > 0"/>
                            </b-form-group>
                        </b-col> 
                    </b-row>

                    <b-row>
                        <b-col md="7" sm="12">
                            <b-form-group label="Chave NF" label-for="chave-nf-conta-receber">
                                <b-form-input id="chave-nf-conta-receber" type="text"
                                    v-mask="mascaras.nf"
                                    :formatter="upperCase"
                                    v-model="contaReceber.chave_nf" required
                                    :readonly="mode === 'remove' || contaReceber.id_contrato > 0"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="5" sm="12">
                            <b-form-group label="Protocolo" label-for="protocolo-conta-receber">
                                <b-form-input id="protocolo-conta-receber" type="text"
                                    v-mask="mascaras.prot"
                                    :formatter="upperCase"
                                    v-model="contaReceber.protocolo" required
                                    :readonly="mode === 'remove' || contaReceber.id_contrato > 0"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card> 
                <br v-if="!gerarFatura">      
                <b-card header="Financeiro"  v-if="!gerarFatura">  
                    <b-row> 
                        <b-col md="4" sm="12">
                            <b-form-group label="Plano de Contas" label-for="conta-receber-plano-conta">
                                <b-form-select id="conta-receber-plano-conta" v-model="contaReceber.id_plano_conta" :options="planoContas" :disabled="mode === 'remove' || contaReceber.id_contrato > 0"/>
                            </b-form-group>
                        </b-col>                      
                        <b-col md="4" sm="12">
                            <b-form-group label="Forma" label-for="conta-receber-forma">
                                <b-form-select id="conta-receber-forma" v-model="contaReceber.id_forma_pagar_receber" :options="formas" :disabled="mode === 'remove' || contaReceber.id_contrato > 0"/>
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="4" sm="12">
                            <b-form-group label="Condição" label-for="conta-receber-condicao">
                                <b-form-select id="conta-receber-condicao" v-model="contaReceber.id_condicao_pagar_receber" :options="condicoes" :disabled="mode === 'remove' || contaReceber.id_contrato > 0"/>
                            </b-form-group>
                        </b-col>
                    </b-row>  
                    <b-row>
                        <b-col md="12" sm="12" class="d-flex justify-content-end" v-if="!contaReceber.id_contrato">
                            <b-button variant="success" @click="gerarParcelas">Gerar parcelas</b-button>
                        </b-col>
                    </b-row>                 
                </b-card>
                <br v-if="!gerarFatura"> 
                
                <b-card header="Parcelas" :style="gerarFatura ? 'margin: -21px': ''">  
                    <b-collapse id="collapse-1" class="mt-2" v-model="viewEdit">
                        <b-card :header="`${modoParcela} parcela #${parcela.num_parc}`" 
                            :border-variant="variacaoParcela"
                            :header-border-variant="variacaoParcela"> 

                            <b-row v-if="parcela.id_boleto_emitido > 0">
                                <b-col md="12" sm="12">
                                    <h5 style="color: red">ATENÇÃO: Apenas o vencimento pode ser alterado em parcelas que possuem boleto emitido.</h5>
                                </b-col>
                            </b-row>
                            
                            <b-row>
                                <b-col md="3" sm="12">
                                    <b-form-group label="Vencimento" label-for="vencimento-parcela">
                                        <b-form-input id="vencimento-parcela" type="date"
                                            :formatter="upperCase"
                                            v-model="parcela.vencimento" required
                                            :readonly="mode === 'remove'"                                           
                                            
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <b-form-group label="Valor" label-for="valor-parcela">
                                        <money id="valor-parcela" v-model="parcela.valor" v-bind="money" required class="form-control" :disabled="parcela.id_boleto_emitido > 0"/>                                        
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <b-form-group label="Taxa" label-for="taxa-parcela">
                                        <money id="axa-parcela" v-model="parcela.taxa" v-bind="money" required class="form-control" :disabled="parcela.id_boleto_emitido > 0"/>
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" sm="12" class=" parc-edit-remove">
                                    <b-button variant="success" v-if="modeParcelas === 'save'"
                                        @click="saveParcela"><i class="fa fa-check"></i></b-button>
                                    <b-button variant="danger" v-if="modeParcelas === 'remove'"
                                        @click="removeParcela"><i class="fa fa-trash-o"></i></b-button>
                                    <b-button class="ml-2" @click="resetParcela"><i class="fa fa-ban"></i></b-button>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-collapse> 
                    <b-table hover striped :items="parcelas" :fields="fieldsParc" borderless fixed>                          
                        <template v-slot:cell(actions)="data">
                            <b-button :disabled="botaoInativo || mode === 'remove'" v-b-toggle.collapse-1 variant="warning" @click="loadParcela(data.item)" class="mr-2">
                                <i class="fa fa-pencil"></i>
                            </b-button>
                            <b-button :disabled="botaoInativo || mode === 'remove'" v-b-toggle.collapse-1 variant="danger" @click="loadParcela(data.item, 'remove')">
                                <i class="fa fa-trash"></i>
                            </b-button>
                        </template>
                        <template #cell(valor)="data">                            
                            <money type="text" v-model="data.item.valor" v-bind="money" readonly class="moeda-tabela" />
                        </template>
                        <template #cell(saldo)="data">                            
                            <money type="text" v-model="data.item.saldo" v-bind="money" readonly class="moeda-tabela" />
                        </template>
                        <template #cell(taxa)="data">                            
                            <money type="text" v-model="data.item.taxa" v-bind="money" readonly class="moeda-tabela" />
                        </template>
                    </b-table>
                </b-card>                
                <br>

                <b-row v-if="!gerarFatura">
                    <b-col md="12" sm="12" class="d-flex justify-content-end" v-if="contaReceber.id_conta_receber">                        

                        <i id="popover-target-conta-pagar"  class="fa fa-question-circle info-alteracoes"></i>

                        <b-popover target="popover-target-conta-pagar" triggers="hover" placement="top">
                            <template #title>Alterações</template>
                            <b>Usuário Cadastro:</b> {{contaReceber.usu_cad}} <br>
                            <b>Data de Cadastro:</b> {{contaReceber.data_cad}} <br>
                            <b>Usuário Alteração:</b> {{contaReceber.usu_alt}} <br>
                            <b>Data de Alteração:</b> {{contaReceber.data_alt}}
                        </b-popover>                        
                    </b-col>
                </b-row>
            </b-form>
        </b-card> 

                              
        <b-modal id="modal-emite_boleto" title="Emissão de Boletos" v-model="modalEmiteBoleto" > 

            <b-overlay :show="overGerBoleto" rounded="sm">  
                <b-row> 
                    <b-col md="12" sm="12">
                        <b-form-group label="Conta Bancária" label-for="conta-bancaria">
                            <b-form-select id="conta-bancaria" v-model="paramBoletos.conta" :options="contasBancarias"/>
                        </b-form-group>
                        <b-form-group label="Convênio" label-for="conta-bancaria-conv">
                            <b-form-select id="conta-bancaria-conv" v-model="paramBoletos.convenio" :options="contasBancariasConv" :disabled="paramBoletos.conta < 1"/>
                        </b-form-group>
                    </b-col> 
                </b-row>
                <br>
                <p><strong>Selecione as parcelas que deseja gerar boletos</strong></p>

                <b-table 
                    sort-icon-left
                    hover 
                    striped 
                    :items="parcelas" 
                    :fields="fieldsParcBoleto"
                    :select-mode="'multi'"
                    @row-selected="onRowSelected"                                
                    ref="selectableTableParcelas"                            
                    selectable
                    responsive
                    borderless
                >  
                    <template #head(selected) v-if="parcelas.length > 0">                                    
                        <template v-if="parcelas.length === selecteds.length">
                            <div style="margin-bottom: -5px">
                                <span  aria-hidden="true" @click="clearSelected"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-check-square-o"></i></span>
                            </div>
                        </template>
                        <template v-if="parcelas.length !== selecteds.length"> 
                            <div style="margin-bottom: -5px">
                                <span aria-hidden="true" @click="selectAllRows"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-square-o"></i></span>
                            </div>
                        </template>
                    </template>

                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-check"></i></span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                        </template>
                    </template> 
                </b-table> 
            </b-overlay>        
                
            <template #modal-footer>
                <div class="w-100"> 
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right  ml-3"
                        @click="$bvModal.hide('modal-emite_boleto')"
                        :disabled="overGerBoleto"
                    >
                        Cancelar
                    </b-button>                   
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right"
                        @click="gerarBoletos()" 
                        :disabled="overGerBoleto"                           
                    >
                        Gerar
                    </b-button>
                    
                </div>
            </template>

        </b-modal>
            

        <b-table 
            v-show="show" v-if="!gerarFatura && !id_lanc"
            :items="contasReceber" 
            :fields="fields" 
            :per-page="limit" 
            :current-page="page"

            @row-clicked="rowClicked"
            :tbody-tr-class="tbodyRowClass"
            primary-key="id"

            ref="selectableTableFaturas"  
            hover 
            sort-icon-left 
            small 
            striped 
            borderless                                
            responsive
        >

            <template #head(selected) v-if="contasReceber.length > 0">                                    
                <template v-if="contasReceber.length === selectedRows.length">
                    <div style="margin-bottom: -5px">
                        <span  aria-hidden="true" @click="clearSelectedFaturas"><i style="font-size: 23px; color: #5d5cfc" class="fa fa-check-square-o"></i></span>
                    </div>
                </template>
                <template v-if="contasReceber.length !== selectedRows.length"> 
                    <div style="margin-bottom: -5px">
                        <span aria-hidden="true" @click="selectAllRowsFaturas"><i style="font-size: 23px; color: #5d5cfc" class="fa fa-square-o"></i></span>
                    </div>
                </template>
            </template>            

            <!-- <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                    <span aria-hidden="true"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-check"></i></span>
                </template>
                <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                </template>
            </template> -->

            <template v-slot:cell(selected)="{ item, field: { key } }" >
                <span v-if="item[key]"><i style="font-size: 23px; color: #5d5cfc" class="fa fa-check-square-o"></i></span>
                <span v-else><i style="font-size: 23px; color: #5d5cfc" class="fa fa-square-o"></i></span>
            </template>

            <template v-slot:cell(status)="{ value, item }" >
                <span>
                    <div style="width: 70px"><strong>{{value}}</strong></div>                  
                    <i v-if="item.lote_nfse" style="color: #5dc289" class="fa fa-file-text-o mr-2" v-b-tooltip.hover.bottomright="'NFS-e emitida'"></i>
                    <i v-if="item.id_boleto_emitido > 0" style="color: #5d5cfc" class="fa fa-barcode mr-2" v-b-tooltip.hover.bottomright="'Boleto emitido'"></i>                    
                </span>
            </template>
            
            <template v-slot:cell(actions)="data">
                <div  class="d-flex justify-content-end">
                    <b-button size="sm" variant="primary" @click="loadContaReceber(data.item)" class="mb-1 mr-1" v-b-tooltip.hover.bottomleft="'Editar'">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button size="sm" variant="success" @click="receberConta(data.item)" class="mb-1" v-b-tooltip.hover.bottomleft="'Receber'">
                        <i class="fa fa-usd m-1"></i>
                    </b-button>
                    <!-- <b-dropdown id="dropdown-right" right text="" variant="primary" class="mb-1 ml-1">
                        <b-dropdown-item href="#" @click="gerarNFSe(data.item)"><i class="fa fa-file-code-o mr-1"></i> Gerar NFS-e</b-dropdown-item>
                        <b-dropdown-item href="#" @click="gerarBoletoModal(data.item)"><i class="fa fa-barcode mr-1"></i> Gerar Boleto</b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item href="#" @click="loadContaReceber(data.item, 'remove')"><i class="fa fa-trash mr-1"></i> Excluir fatura</b-dropdown-item>
                    </b-dropdown> -->
                </div>
            </template>
        </b-table>
        <hr>

        <b-pagination 
            :current-page="page"
            v-show="show" v-if="!gerarFatura && !id_lanc"
            align="center" 
            size="md" 
            v-model="page"  
            :total-rows="count" 
            :per-page="limit" 
            first-number 
            last-number
        >
        </b-pagination>                
        
        <div v-show="!show" style="margin-top: 10px">
                <div class="acoesRodape">                 
                <b-button variant="danger " class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')" v-b-tooltip.hover.topleft="'Cancelar'"
                    @click="reset">
                        <i class="fa fa-times button-icons-acoes"></i>
                </b-button>
                <b-button variant="danger" class="botoesAcaoRodape" v-if="mode === 'remove'" v-b-tooltip.hover.topleft="'Excluir'"
                    @click="remove">
                        <i class="fa fa-trash button-icons-acoes"></i>
                </b-button>
                <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'" v-b-tooltip.hover.topleft="'Salvar'"
                    @click="save">
                        <i class="fa fa-check button-icons-acoes"></i>
                </b-button>  
            </div>
        </div> 

        <b-modal 
            hideBvModalEvent
            v-model="modalOpcoesFaturas"                        
            id="modal-opcoes-faturas" 
            centered size="lg" 
            ok-only
            ok-disabled
            title="Opções de Faturas"
        >  
            <div style="min-height: 428px">
                <b-tabs content-class="mt-3" fill>
                    <b-tab title="Gerar NFS-e" active>
                        <b-row>
                            <b-col md="12" sm="12" >
                            </b-col>
                        </b-row>
                        <br>
                        <b-row>
                            <b-col md="12" sm="12" lg="3">
                                <b-button size="lg" variant="success" @click="gerarNFSe(null)"><i class="fa fa-cloud-download mr-2" aria-hidden="true"></i>Gerar NFS-e</b-button>
                            </b-col>
                        </b-row>
                    </b-tab>

                    <b-tab title="Gerar Boletos">
                        <b-row> 
                            <b-col md="12" sm="12">
                                <b-form-group label="Conta Bancária" label-for="conta-bancaria">
                                    <b-form-select id="conta-bancaria" v-model="paramBoletos.conta" :options="contasBancarias"/>
                                </b-form-group>
                                <b-form-group label="Convênio" label-for="conta-bancaria-conv">
                                    <b-form-select id="conta-bancaria-conv" v-model="paramBoletos.convenio" :options="contasBancariasConv" :disabled="contasBancariasConv.length < 1"/>
                                </b-form-group>
                            </b-col> 
                        </b-row>
                        <br>

                        <b-row>
                            <b-col md="12" sm="12">
                                    <b-button size="lg" variant="success" @click="gerarBoletos()"><i class="fa fa-barcode mr-2" aria-hidden="true"></i>Gerar Boletos</b-button>
                            </b-col> 
                        </b-row>
                    </b-tab>
                    
                </b-tabs>
            </div>
            
            <br> 

            <b-card style="width: 100%" v-if="false">
                <vue-pdf-embed 
                :source="boletoPdf" />
            </b-card>   

            <b-card style="width: 100%">
                <div class="text-center text-primary my-2">
                    <b-spinner v-if="statusImpressao === 'sucesso'" class="align-middle mr-1"></b-spinner>
                    <strong>{{ mensagemImpressao }}</strong>
                </div>                  
            </b-card> 
            
            <template #modal-footer>
                <div class="w-100"> 
                </div>
            </template>                  
        </b-modal>

        <b-modal 
            v-model="showModalLiquidar"
            id="modal-lg-liquidar" 
            centered size="lg" 
            ok-only 
            title="Liquidação de Faturas"
        >
            <div style="min-height: 550px">
                <b-tabs > 
                    <b-tab title="Resumo" class="pt-3">  
                        <b-row>
                            <b-col md="6" sm="6">
                                <b-form-group label="Valor a Receber" label-for="liquidar-vlr-receber">
                                    <money style="font-size: 1.3rem; margin: -5px 0 -5px 0;" id="liquidar-vlr-receber" type="text" v-model="liquidacao.saldoLiquidar" v-bind="money" class="form-control" />
                                </b-form-group>
                                <b-form-group label="Acréscimo" label-for="liquidar-vlr-acrescimo">
                                    <money style="font-size: 1.3rem; margin: -5px 0 -5px 0;" id="liquidar-vlr-acrescimo" type="text" v-model="liquidacao.acrescimo" v-bind="money" class="form-control" />
                                </b-form-group>
                                <b-form-group label="Desconto" label-for="liquidar-vlr-desconto">
                                    <money style="font-size: 1.3rem; margin: -5px 0 -5px 0;" id="liquidar-vlr-desconto" type="text" v-model="liquidacao.desconto" v-bind="money" class="form-control" />
                                </b-form-group>

                                <b-card header="Total Receber" header-bg-variant="success" header-text-variant="white">
                                    <h4><strong>{{liquidacao.totalReceber.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</strong></h4>
                                </b-card>
                                <br>
                                <b-card>
                                    <span><strong>Valor a Receber</strong></span> <br>
                                    <h4 style="font-size: 2rem"><strong>{{liquidacao.totalLiquidacaoAbs.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</strong></h4>
                                </b-card>                        
                            </b-col>

                            <b-col md="6" sm="6">
                                <b-card header="Numerários" style="margin-bottom: 15px">
                                    <b-form-group label="Cartão" label-for="liquidar-cartoes">
                                        <money style="font-size: 1.3rem; margin: -5px 0 -5px 0;" id="liquidar-cartoes" type="text" v-model="caixaMovimento.totalCartoes" v-bind="money" readonly class="form-control" />
                                    </b-form-group>

                                    <b-form-group label="Cheque" label-for="liquidar-cheques">
                                        <money style="font-size: 1.3rem; margin: -5px 0 -5px 0;" id="liquidar-cheques" type="text" v-model="caixaMovimento.totalCheques" v-bind="money" readonly class="form-control" />
                                    </b-form-group>

                                    <b-form-group label="Depósito Bancário" label-for="liquidar-deposito">
                                        <money style="font-size: 1.3rem; margin: -5px 0 -5px 0;" id="liquidar-deposito" type="text" v-model="caixaMovimento.totalDepositos" v-bind="money" readonly class="form-control" />
                                    </b-form-group>
                                    
                                    <b-form-group label="Dinheiro" label-for="liquidar-dinheiro" style="margin-bottom: 0px">
                                        <money style="font-size: 1.3rem; margin: -5px 0 -5px 0;" id="liquidar-dinheiro" type="text" v-model="caixaMovimento.totalDinheiro" v-bind="money" readonly class="form-control" />
                                    </b-form-group>
                                </b-card>
                                <b-form-textarea
                                    placeholder="Informe uma observação..."
                                    v-model="liquidacao.obs"
                                    rows="2"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                    </b-tab>

                    <!-- Para contas a receber -->
                    <b-tab class="pt-3" title="Cartão" v-if="liquidacao.totalLiquidacao > 0">
                        <b-row>
                            <b-col md="5" sm="5">
                                <b-form-group label="Admin" label-for="liquidacao-adm-cartao">
                                    <b-form-select id="liquidacao-adm-cartao" v-model="cartao.id" :options="administradoras" />
                                </b-form-group>
                                <b-form-group label="Condição" label-for="liquidacao-parc-cartao">
                                    <b-form-select 
                                        id="liquidacao-parc-cartao" 
                                        v-model="cartao.id_cartao_bandeira" 
                                        :options="cartaoCondicoes" 
                                        :disabled="cartaoCondicoes.length < 1 "
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="3">
                                <b-form-group label="Autorização" label-for="liquidacao-auto-cartao">
                                    <b-form-input id="liquidacao-auto-cartao" type="text" v-model="cartao.autorizacao"/>
                                </b-form-group>
                                <b-form-group label="Qtde. Parcelas" label-for="liquidacao-auto-qtde">
                                    <b-form-input id="liquidacao-auto-qtde" type="number" v-model="cartao.qtde_parcelas"/>
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="3">
                                <b-form-group label="Valor" label-for="liquidacao-valor-cartao">
                                    <money id="liquidacao-valor-cartao" type="text" v-model="cartao.valor" v-bind="money" class="form-control" />
                                </b-form-group>
                            </b-col>
                            <b-col md="1" sm="1">
                                <b-button style="margin-top: 30px" variant="success" @click="gravaCartao()" class="botao-checkar">
                                            <i class="fa fa-check"></i>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table 
                                    small 
                                    striped 
                                    :items="caixaMovimento.cartoes" 
                                    :fields="fieldsCartao" 
                                    borderless 
                                    responsive 
                                    sort-icon-left
                                >   
                                    <template v-slot:cell(actions)="data">
                                        <div class="d-flex justify-content-end">
                                            <b-button variant="danger" @click="excluirCartao(data.item)">
                                                <i class="fa fa-times"></i>
                                            </b-button>
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>

                    </b-tab>

                    <!-- Para contas a receber -->
                    <b-tab class="pt-3" title="Cheque" v-if="liquidacao.totalLiquidacao > 0">
                        <b-row>
                            <b-col md="4" sm="4">
                                <b-form-group label="Nº Banco" label-for="liquidacao-cheque-banco">
                                    <b-form-input id="liquidacao-cheque-banco" type="number" v-model="cheque.num_banco"/>
                                </b-form-group>
                            </b-col>

                            <b-col md="4" sm="4">
                                <b-form-group label="Agência" label-for="liquidacao-cheque-agencia">
                                    <b-form-input id="liquidacao-cheque-agencia" type="number" v-model="cheque.agencia"/>
                                </b-form-group>
                            </b-col>

                            <b-col md="4" sm="4">
                                <b-form-group label="Conta" label-for="liquidacao-cheque-conta">
                                    <b-form-input id="liquidacao-cheque-conta" type="number" v-model="cheque.conta"/>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="4" sm="4">
                                <b-form-group label="Nº Cheque" label-for="liquidacao-cheque-num">
                                    <b-form-input id="liquidacao-cheque-num" type="number" v-model="cheque.num_cheque"/>
                                </b-form-group>
                            </b-col>

                            <b-col md="4" sm="4">
                                <b-form-group label="Vencimento" label-for="liquidacao-cheque-vencimento">
                                    <b-form-input id="liquidacao-cheque-vencimento" type="date" v-model="cheque.vencimento"/>
                                </b-form-group>
                            </b-col>

                            <b-col md="4" sm="4">
                                <b-form-group label="Valor" label-for="liquidacao-cheque-valor">
                                    <money id="liquidacao-cheque-valor" type="text" v-model="cheque.valor" v-bind="money" class="form-control"/>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>                           
                            <b-col md="7" sm="8">
                                <b-form-group label="Nome do Emissor" label-for="liquidacao-cheque-nome-em">
                                    <b-form-input id="liquidacao-cheque-nome-em" type="text" v-model="cheque.nome_emissor"/>
                                </b-form-group>                                
                            </b-col>

                            <b-col md="4" sm="4">
                                <b-form-group label="CPF/CNPJ do Emissor" label-for="liquidacao-cheque-cpf-cnpj-emissor">
                                    <b-form-input id="liquidacao-cheque-cpf-cnpj-emissor" type="number" v-model="cheque.cpf_cnpj_emissor"/>
                                </b-form-group>
                            </b-col>

                            <b-col md="1" sm="1">
                                <b-button style="margin-top: 30px" variant="success" @click="gravaCheque()" class="botao-checkar">
                                            <i class="fa fa-check"></i>
                                </b-button>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-table 
                                    small 
                                    striped 
                                    :items="caixaMovimento.cheques" 
                                    :fields="fieldsCheque" 
                                    borderless 
                                    responsive 
                                    sort-icon-left
                                >   
                                    <template v-slot:cell(actions)="data">
                                        <div class="d-flex justify-content-end">
                                            <b-button variant="danger" @click="excluirCheque(data.item)">
                                                <i class="fa fa-times"></i>
                                            </b-button>
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>

                    </b-tab>
                    
                    <!-- Para contas a receber/pagar -->
                    <b-tab class="pt-3" title="Depósito bancário">
                        <b-row>
                            <b-col md="8" sm="3">
                                <b-form-group label="Conta" label-for="liquidacao-dep-conta">
                                    <b-form-select id="liquidacao-dep-cont" v-model="depositoBancario.id_conta_bancaria" :options="contasBancarias" />
                                </b-form-group>
                            </b-col>                    
                            <b-col md="3" sm="3">
                                <b-form-group label="Valor" label-for="liquidacao-dep-valor">
                                    <money id="liquidacao-dep-valor" type="text" v-model="depositoBancario.valor" v-bind="money" class="form-control" />
                                </b-form-group>
                            </b-col>
                            <b-col md="1" sm="1">
                                <b-button style="margin-top: 30px" variant="success" @click="gravarDeposito()" class="botao-checkar">
                                            <i class="fa fa-check"></i>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table 
                                    small 
                                    striped 
                                    :items="caixaMovimento.depositosBancario" 
                                    :fields="fieldsDeposito" 
                                    borderless 
                                    responsive
                                    sort-icon-left
                                >  
                                    <template v-slot:cell(id_conta_bancaria)="data">
                                        <b-form-select id="liquidacao-dep-cont" v-model="data.item.id_conta_bancaria" :options="contasBancarias" disabled/>
                                        {{data}}
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <div class="d-flex justify-content-end"> 
                                            <b-button variant="danger" @click="excluirDeposito(data.item)">
                                                <i class="fa fa-trash-o"></i>
                                            </b-button>
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </b-tab>
                    
                </b-tabs>
            </div>

            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="primary"
                        size="lg"
                        class="float-right"
                        @click="salvarLiquidacao"
                    >
                        Liquidar
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal size="xl" id="modal-rel-faturas" title="Relatório de Contas a Receber" v-model="modalRelFaturas">

            <b-form @submit.prevent="gerarRelatorio">
                <b-row>
                    <b-col md="12" sm="12">
                        <b-input-group>
                            <b-form-select id="rel-tipo" placeholder="Selecione o Tipo" v-model="filters.tipo" :options="tipoOptions" style="width: 10px"/>
                            <b-form-select id="rel-plano-ct" placeholder="Plano de Contas" v-model="filters.plano_contas" :options="planoContas" />
                            <b-form-select id="rel-filial" placeholder="Filial" v-model="filters.filial" :options="filiais" />
                            <b-form-datepicker
                                id="dtp-inicio"
                                placeholder="Início"
                                v-model="filters.data_inicio"
                                v-bind="labels[locale] || {}"
                                locale="pt-BR"
                                :hide-header="true"
                                class="mb-2"
                                required
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            ></b-form-datepicker>
                            <b-form-datepicker
                                id="dtp-fim"
                                placeholder="Fim"
                                v-model="filters.data_fim"
                                v-bind="labels[locale] || {}"
                                locale="pt-BR"
                                :hide-header="true"
                                class="mb-2"
                                required
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            ></b-form-datepicker>
                            <b-input-group-append>
                                <b-button style="height: 38px" variant="info" @click="gerarRelatorio()">
                                    <i class="fa fa-file-text-o button-icons"></i> Gerar
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>                   

                    <b-col md="12" sm="12" class="d-flex justify-content-end"  v-if="relResults.length > 0"> 

                        <div>
                            <b-input-group>
                                <b-button 
                                    class="botoes-de-impressao-download"  
                                    variant="info" 
                                    @click="imprimirPdf('render-pdf-rel')" 
                                    v-b-tooltip.hover.bottomleft="'Imprimir'"
                                > 
                                    <i class="fa fa-print button-icons"></i>
                                </b-button> 

                                <b-button  
                                    class="botoes-de-impressao-download" 
                                    variant="danger" 
                                    @click="salvarPdf('render-pdf-rel')" 
                                    v-b-tooltip.hover.bottomleft="'Baixar PDF'"
                                >
                                    <i class="fa fa-file-pdf-o button-icons"></i>
                                </b-button>                                 

                                <b-button 
                                    class="botoes-de-impressao-download"  
                                    variant="success" 
                                    @click="salvarExcel()" 
                                    v-b-tooltip.hover.bottomleft="'Exportar para Excel'"
                                > 
                                    <i class="fa fa-table button-icons"></i>
                                </b-button> 

                            </b-input-group>
                        </div>                      

                    </b-col>

                    
                </b-row>
            </b-form>  

            <b-card class="mt-3">
                <b-row v-if="relResults.length > 0">
                    <b-col md="12" sm="12" class="d-flex justify-content-center"> 
                        <div  id="render-pdf-rel" style="width: 775px; background-color: white">  
                            <h3>{{tituloRel}}</h3>
                            <h4>{{nomeFilial}}</h4>
                            <h5>Período: {{dtIni}} até {{dtFin}}</h5>
                            <hr>

                            <b-table 
                                borderless
                                small                                        
                                responsive
                                sort-icon-left
                                head-variant="light"
                                :items="tableData" 
                                :fields="fieldsFaturas"
                            >
                            </b-table>

                        </div>
                    </b-col>
                </b-row>                
            </b-card> 
            
            <template #modal-footer>
                <div class="w-100 h-50">                    
                    
                </div>
            </template>

        </b-modal>
        
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import Autocomplete from '../../autocomplete/Autocomplete.vue'
import {VMoney} from 'v-money'
import axios from 'axios'
import { showSuccess } from '../../../global'
const moment = require('moment')
import html2pdf from "html2pdf.js"
import 'moment/locale/pt-br';
moment.locale('pt-br');
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver'

import Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'; // Não esqueça de importar o CSS
import 'vue2-datepicker/locale/pt-br';

export default {
    name: 'CadastroFaturaReceber',
    components: { Autocomplete, Datepicker },
    props: ['fatura', 'gerarFatura', 'id_lanc', 'loadFaturaVenda', 'resetFaturaVenda'],
    emits: ['enviaParcelas', 'salvar-receita'],    
    data: function() {
        return {
            locale:"pt-BR",
            labels: {
                "pt-BR": {
                    labelPrevDecade: "Década Anterior",
                    labelPrevYear: "Ano Anterior",
                    labelPrevMonth: "Mês Anterior",
                    labelCurrentMonth: "Mês Atual",
                    labelNextMonth: "Próximo Mês",
                    labelNextYear: "Próximo Ano",
                    labelNextDecade: "Próxima Década",
                    labelToday: "Hoje",
                    labelSelected: "Data Selecionada",
                    labelNoDateSelected: "Nenhuma Data Selecionada",
                    labelCalendar: "Calendário",
                    labelNav: "Navegação do Calendário",
                    labelHelp: "Navegue pelo calendário usando as teclas de seta"
                }
            },
            filters: {
                data_inicio: moment().add(-1, 'M').format('YYYY-MM-01'),
                data_fim: moment().add(-1, 'M').endOf('month').format('YYYY-MM-DD'),
                plano_contas: 0,
                filial: 0,
                tipo: 'A'
            },
            dtIni: '',
            dtFin: '',
            nomeFilial: '',
            tipoOptions: [
                { text: 'Sintético', value: 'S' },
                { text: 'Analítico', value: 'A' }
            ],
            relResults: [],
            tituloRel: '',
            nomeArquivo: '',
            fieldsFaturas: [],
            modalRelFaturas: false,

            testes: {},
            
            intervaloVenc: [],
            intervaloComp: [],            
            consulta: {
                parcial: '',
                dataIniComp: null,
                dataFimComp: null,
                dataIniVenc: null,
                dataFimVenc: null,
                filial: 0,
                idPlanoConta: 0,
                status: ['A','P'],                
                // e_boleto: 'T',
                opcoes: [
                    { value: 'A', text: 'Abertas' },
                    { value: 'P', text: 'Parciais'},
                    { value: 'Q', text: 'Quitadas' },
                    { value: 'CN', text: 'Com nota' },
                    { value: 'SN', text: 'Sem nota' },
                    { value: 'CB', text: 'Com boleto' },
                    { value: 'SB', text: 'Sem boleto' }
                ]  
            },
            modalRemFatura: false,
            modalOpcoesFaturas: false,
            statusImpressao: '',
            mensagemImpressao: '',
            viewEdit: false,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            index: -1,
            mode: 'new',
            modalEmiteBoleto: false,
            overGerBoleto: false,
            selecteds: [],
            faturasSelecteds: [],
            idsParcelas: [],
            faturasSelecionadas: [],
            paramBoletos: {
                conta: 0,
                filialContaBancaria: 0,
                parcelas: [],
                convenio: 0
            },
            administradoras: [],
            contasBancarias: [],
            contasBancariasConv: [],
            filiais: [],
            botaoInativo: false,
            modeParcelas: 'save',
            variacaoParcela: 'warning',
            modoParcela:'Editar',
            contaReceber: {
                id_lancamento: this.id_lanc || 0,
                id_pessoa: '',
                descricao: '',
                valor: 0.00,
                data_competencia: moment().format('YYYY-MM-DD'),
                data_documento: moment().format('YYYY-MM-DD'),
                status: 'A',
            },
            idContRec: 0,
            contasReceber: [],
            pessoas: [],
            formas: [],
            condicoes: [],
            planoContas: [],
            lancamentos: [],
            parcela: {},
            parcelas: [],
            page: 1,
            count: 0,
            limit: 0,
            parcial: '',
            status: 'A',
            show: true,
            showModalLiquidar: false,
            liquidacao: {
                totalReceber: 0,
                totalLiquidacao: 0,
                totalLiquidacaoAbs: 0,
                saldoFatura: 0,
                saldoLiquidar: 0,
                acrescimo: 0,
                desconto: 0,
                obs: ''
            },
            faturaReceber: {},

            cartaoCondicoes: [],
            
            caixaMovimento: {
                id_caixa: 0,
                id_plano_contas: 0,
                data_movimento: moment().format('YYYY-MM-DD'),

                cheques: [],
                cartoes: [],
                depositosBancario: [],

                totalCheques: 0,
                totalCartoes: 0,
                totalDepositos: 0,
                totalDinheiro: 0,

                valor_recebido: 0,
                valor_pago: 0,
                totalGeral: 0,

                historico: '',

                tipo_movimento: 'L',
            },
            cartao: {                
                id: 0,
                id_cartao_bandeira: 0,
                qtde_parcelas: 1,
                autorizacao: '',
                valor: 0,
                descricao: ''
            },
            cheque: {
                id: 0,
                id_caixa_movimento: 0,
                num_cheque: '',
                agencia: '',
                conta: '',
                cpf_cnpj_emissor: '',
                nome_emissor: '',
                vencimento: moment().format('yyyy-MM-DD'),
                valor: 0,
            },
            depositoBancario: {
                id_conta_bancaria: 0,
                valor: 0
            },

            mascaras: {
                masked: true,
                cpf: '###.###.###-##',
                cnpj: '##.###.###/####-##',
                telefone: '(##) ####-####',
                celular: '(##) # ####-####',
                cep: '#####-###',
                cfop: '#.###',
                nf: '##  ####  ##############  ##  ###  #########  #  ########  #',
                docto: '####################',
                serie: 'NNN',
                mod: 'NNN',
                prot: '#############################################'
            },
            fields: [
                {key: 'selected', label: '', sortable: false},
                {key: 'razao_social', label: 'Nome', sortable: true},
                {key: 'num_fatura', label: 'Fatura', sortable: true},
                {key: 'num_parc', label: 'Parc', sortable: true},
                {key: 'num_docto', label: 'Docto', sortable: true},
                {key: 'data_competencia', label: 'Competência', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'vencimento', label: 'Vencimento', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'saldo', label: 'Saldo', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'status', label: 'Status', 
                    formatter: value => { 
                        switch (value) {
                            case 'A': return "Aberta" 
                            case 'P': return "Parcial" 
                            case 'Q': return "Quitada"
                            case 'R': return "Renegociada"                        
                            case 'C': return "Cancelada"
                        }
                    }
                },
                {key: 'actions', label: ''}
            ],
            fieldsParc: [
                //{key: 'id_conta_receber', label: 'Index', sortable: true},
                {key: 'num_parc', label: 'Nº Parc', sortable: true},
                {key: 'vencimento', label: 'Venc.', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'valor', label: 'Valor', sortable: true},
                {key: 'saldo', label: 'Saldo', sortable: true},
                {key: 'taxa', label: 'Taxa', sortable: true},                
                {key: 'actions', label: ''}
            ],
            fieldsParcBoleto: [
                {key: 'selected', label: '', sortable: false},
                {key: 'num_parc', label: 'Parcela', sortable: false},
                {key: 'vencimento', label: 'Vencimento', sortable: false},
                {key: 'valor', label: 'Valor', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'saldo', label: 'Saldo', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
            ],

            fieldsDeposito: [
                // {key: 'id_conta_bancaria', label: 'Conta', sortable: true},
                {key: 'nome_conta', label: 'Conta', sortable: true},
                {key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },              
                {key: 'actions', label: ''}
            ],

            fieldsCartao: [
                {key: 'descricao', label: 'Descrição', sortable: true},
                {key: 'qtde_parcelas', label: 'Qtde. Parcelas', sortable: true},
                {key: 'autorizacao', label: 'Autorizacao', sortable: true},
                {key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },              
                {key: 'actions', label: ''}
            ],
            fieldsCheque: [
                {key: 'descricao', label: 'Cheque', sortable: true},
                {key: 'vencimento', label: 'Vencimento', sortable: true,
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },              
                {key: 'actions', label: ''}
            ],
        }
    },
    directives: {
        money: VMoney
    },
    computed: {
        selectedRows() {
            return this.contasReceber.filter(item => item.selected)
        },

        tableData() {

            let ret = []

            if (this.filters.tipo === 'A') {
                const totalValor = this.relResults.reduce((sum, item) => sum + (item.valor || 0), 0);
                const totalSaldo = this.relResults.reduce((sum, item) => sum + (item.saldo || 0), 0);
                // Adicionar linha de total ao final
                ret = [
                    ...this.relResults,
                    {
                        id_conta_receber: '',
                        id_forma_pagar_receber: '',
                        id_condicao_pagar_receber: '',
                        id_pessoa: '',
                        razao_social: 'TOTAL',
                        id_filial: '',
                        id_empresa: '',
                        id_plano_conta: '',
                        data_competencia: '',
                        data_documento: '',
                        valor: totalValor,
                        saldo: totalSaldo,
                        _rowVariant: 'danger'
                    }
                ];
            }

            if (this.filters.tipo === 'S') {
                const totalValor = this.relResults.reduce((sum, item) => sum + (item.total_valor || 0), 0);
                // Adicionar linha de total ao final
                ret = [
                    ...this.relResults,
                    {
                        id_plano_conta: '',
                        descricao: 'Total',
                        total_valor: totalValor,
                        _rowVariant: 'danger'
                    }
                ];
            }

            return ret
        }
    },
    methods: {
        novaFatura() {
            this.loadPlanoContas(false),
            this.loadFormas(),
            this.loadCondicoes(),
            this.show = false
            this.mode = 'save'
        },     

        receberConta(f) {
            this.testes = f
            const c = localStorage.getItem('__lancamentos_caixa') 
            const idCaixa = c ? JSON.parse(c).idCaixa : 0

            if (idCaixa == 0) {this.$toasted.info('Você precisa logar em um caixa para continuar.'); return;} 
            if ( !(f.saldo > 0) ) {this.$toasted.info('O saldo da fatura precisa ser maior que zero.'); return;}

            this.caixaMovimento.totalCheques = 0
            this.caixaMovimento.totalCartoes = 0
            this.caixaMovimento.totalDepositos = 0 
            this.caixaMovimento.depositosBancario = []
            this.caixaMovimento.cartoes = []
            this.caixaMovimento.cheques = []

            this.faturaReceber = { ...f }

            f.liquidar = (f.valor_liquido + f.desconto) - f.acrescimo

            let receber = f.saldo

            this.liquidacao.totalReceber = receber
            this.liquidacao.saldoLiquidar = receber
            this.liquidacao.saldoFatura = receber

            this.calculaTotal()
            this.loadAdministradoras()
            this.loadContasBancarias()
            
            this.showModalLiquidar = true

        },

        // carregaFaturas() {
        //     let receber = 0
        //     let pagar = 0
        //     let acrescimosReceber = 0
        //     let descontosReceber = 0
        //     let acrescimosPagar = 0
        //     let descontosPagar = 0

        //     this.selectedsFaturas.forEach((fatura)=>{

        //         fatura.liquidar = (fatura.valor_liquido + fatura.desconto) - fatura.acrescimo

        //         if (fatura.tipo === 'Receber') {
        //             receber = receber + fatura.valor_liquido
        //             acrescimosReceber = acrescimosReceber + fatura.acrescimo
        //             descontosReceber = descontosReceber + fatura.desconto
        //         }
        //         if (fatura.tipo === 'Pagar') {
        //             pagar = pagar + fatura.valor_liquido
        //             acrescimosPagar = acrescimosPagar + fatura.acrescimo
        //             descontosPagar = descontosPagar + fatura.desconto
        //         }
        //     })

        //     this.liquidacao.totalReceber = receber
        //     this.liquidacao.totalPagar = pagar

        //     this.calculaTotal()
        // },

        calculaTotal() {
            const t = this.liquidacao.totalReceber 
            this.liquidacao.totalLiquidacao = t
            this.liquidacao.totalLiquidacaoAbs = t
            this.caixaMovimento.tipo_movimento = 'C'  
            this.calculaDinheiro()
        },

        calculaDinheiro(){
            this.caixaMovimento.totalDinheiro = this.liquidacao.totalLiquidacaoAbs - 
                (this.caixaMovimento.totalCheques + this.caixaMovimento.totalCartoes + this.caixaMovimento.totalDepositos)

            this.depositoBancario.valor = this.caixaMovimento.totalDinheiro
            this.cartao.valor = this.caixaMovimento.totalDinheiro
            this.cheque.valor = this.caixaMovimento.totalDinheiro
            
        },

        loadContasBancarias() {
            const url = `${baseApiUrl}/contas_bancarias`

            axios.get(url).then(res => {
                this.contasBancarias = res.data.map(contaBancaria => {
                    return { ...contaBancaria, value: contaBancaria.id_conta_bancaria, text: `CC: ${contaBancaria.conta} | AG: ${contaBancaria.agencia} (${contaBancaria.nome_banco})` }
                })
            })
            .catch(err => {
                showError(err)
            }) 
        },
        loadAdministradoras() {
            const url = `${baseApiUrl}/cartao_adm`

            axios.get(url).then(res => {
                this.administradoras = res.data.map(administradora => {
                    return { ...administradora, value: administradora.id_cartao_adm, text: `${administradora.id_cartao_adm} | ${administradora.descricao}` }
                })
            })
        },

        selectAllRowsFaturas() {
            this.contasReceber.forEach((item) => {
                this.$set(item, 'selected', true)
                this.selectedsFaturas = [ ...this.selectedRows ]
                this.selectedsFaturas.forEach((fatura)=>{
                    if (fatura.valor_liquido === 0) fatura.valor_liquido = fatura.saldo
                    if (fatura.liquidar === 0) fatura.liquidar = fatura.saldo 
                })
            })
        },

        clearSelectedFaturas() {
            this.selectedRows.forEach((item) => {
                this.$delete(item, 'selected')
                this.selectedsFaturas = []
            })
        },

        tbodyRowClass(item) {
            /* Style the row as needed */
            if(item.selected){
                return ["b-table-row-selected", "table-primary", "cursor-pointer"]
            } else {
                return ["cursor-pointer"]
            }
        },

        rowClicked(item) {
            if(item.selected){      
                this.$set(item, 'selected', false)

                this.faturasSelecteds = [ ...this.selectedRows ]

                this.paramBoletos.parcelas = this.faturasSelecteds.map(c=> {return c.id_conta_receber_parcela})
                this.idsParcelas = this.faturasSelecteds.map(c=> {return c.id_conta_receber_parcela})
                this.faturasSelecionadas = [ ...new Set(this.faturasSelecteds.map(c=> {return c.id_conta_receber})) ]
            }else{
                this.$set(item, 'selected', true)

                this.faturasSelecteds = [ ...this.selectedRows ]

                this.paramBoletos.parcelas = this.faturasSelecteds.map(c=> {return c.id_conta_receber_parcela})
                this.idsParcelas = this.faturasSelecteds.map(c=> {return c.id_conta_receber_parcela})
                this.faturasSelecionadas = [ ...new Set(this.faturasSelecteds.map(c=> {return c.id_conta_receber})) ]
            }


        },

        async onRowSelected(s) {  
            const temp = [...s]          
            await this.clearSelectedFaturas()
            this.selecteds = temp            
            this.paramBoletos.parcelas = this.selecteds.map(c=> {return c.id_conta_receber_parcela}) 
        },
        selectAllRows() {
            this.$refs.selectableTableParcelas.selectAllRows()
        },
        clearSelected() {
            this.$refs.selectableTableParcelas.clearSelected()
        },

        loadConvenios() {
            if (this.paramBoletos.conta > 0) {
                const url = `${baseApiUrl}/convenios_bancarios/${this.paramBoletos.conta}`    
    
                axios.get(url).then(res => {
                     this.contasBancariasConv = res.data.map(conv => {
                        return { ...conv,  value: conv.id_conta_bancaria_convenio, text: `${conv.descricao} ( Nº Convênio: ${conv.numero} )`}
                    })
                })
                .catch(err => {
                    showError(err)
                }) 
            }
        },
        gerarBoletoModal(b) {
            const url = `${baseApiUrl}/contas_receber_parcelas/boleto/${b.id_conta_receber}`            

            axios.get(url).then(res => {
                res.data.forEach(item =>{
                    item.vencimento = moment(item.vencimento).format('DD/MM/YYYY')   
                    this.parcelas.push(item)                     
                })
                this.loadContasBancarias()                

                this.contaReceber = b
                this.modalEmiteBoleto = true
            })
            .catch(err => {
                showError(err)
            })
        },

        opcoesFaturas() {
            this.modalOpcoesFaturas = true
            this.loadContasBancarias()
        },
        loadFiliais() { 
            const url = `${baseApiUrl}/filiais`    

            axios.get(url)
            .then(res => {
                 this.filiais = res.data.map(filial => {
                    return { ...filial,  value: filial.idfilial, text: `${filial.idfilial} | ${filial.razao_social} (${filial.fantasia})`}
                })

                if (this.modalRelFaturas) {
                    this.filiais.unshift({value: 0, text: 'Todas as Filiais'})
                } else {
                    this.filiais.unshift({value: 0, text: 'TODAS'})
                }
            })
            .catch(err => {
                showError(err)
            })            
        },

        gerarRelatorio() {
            try {                 
                axios.get(`${baseApiUrl}/rel_contas_receber`, {
                    params: { ...this.filters }
                })
                .then(async res => {
                    switch (this.filters.tipo) {
                        case 'A':
                            this.nomeArquivo = 'rel-contas-a-receber(analitico)'
                            this.tituloRel = 'Relatório de Contas a Receber (Analítico)' 
                            this.fieldsFaturas = [
                                {key: 'razao_social', label: 'Nome', sortable: true},
                                {key: 'num_docto', label: 'Docto', sortable: true},
                                {key: 'valor', label: 'Valor', sortable: false,
                                    formatter: value => {
                                        return this.filters.tipo ? value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0
                                    }
                                },
                                {key: 'saldo', label: 'Saldo', sortable: false,
                                    formatter: value => {
                                        return this.filters.tipo ? value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0
                                    }
                                },
                                {key: 'status', label: 'Status', 
                                    formatter: value => { 
                                        switch (value) {
                                            case 'A': return "Aberta" 
                                            case 'P': return "Parcial" 
                                            case 'Q': return "Quitada"
                                            case 'R': return "Renegociada"                        
                                            case 'C': return "Cancelada"
                                        }
                                    }
                                }
                            ]                   
                            break;
                        case 'S':
                            this.nomeArquivo = 'rel-contas-a-receber(sintetico)'
                            this.tituloRel = 'Relatório de Contas a Receberr (Sintético)'  
                            this.fieldsFaturas = [
                                {key: 'id_plano_conta', label: 'Cód', sortable: true},
                                {key: 'descricao', label: 'Plano de Contas', sortable: true},
                                {key: 'total_valor', label: 'Valor Total', sortable: false,
                                    formatter: value => {
                                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                    }
                                },
                            ]                  
                            break;
                    
                        default:
                            this.nomeArquivo = 'rel-contas-a-receber'
                            this.tituloRel = 'Relatório de Contas a Receber' 
                        break;
                    }

                    this.filiais.forEach( obj => {
                        if (obj.value === this.filters.filial) {
                            this.nomeFilial = obj.text
                        } 
                    }) 

                    this.dtIni = moment(this.filters.data_inicio).format('DD/MM/yyyy')
                    this.dtFin = moment(this.filters.data_fim).format('DD/MM/yyyy')

                    this.relResults = res.data

                }) 
                .catch(err => {
                    showError(err)
                })

            } catch (err) {
                showError(err)
            }            
        },

        loadRelFatura() { 
            this.modalRelFaturas = true  
            this.loadPlanoContas(true)
            this.loadFiliais()        
        }, 

        salvarPdf(id){
            var element = document.getElementById(id);
            var pagebreak = { mode: 'avoid-all' };

            // Gerar o PDF.
            html2pdf().from(element).set({
                margin:[0.25,0.25,0.25,0.25],
                filename: `${this.nomeArquivo}.pdf`,
                pagebreak: pagebreak,
                jsPDF: {orientation: 'portrait', unit: 'in', format: 'a4', compressPDF: true}
            })
            .toPdf()
            .get('pdf').then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();

                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(75);
                        pdf.text('Página ' + i + ' de ' + totalPages, ((pdf.internal.pageSize.getWidth()/2)-0.4) , (pdf.internal.pageSize.getHeight()-0.2));
                    }
            })
            .save();
        },

        imprimirPdf(id) {
            var element = document.getElementById(id);
            var pagebreak = { mode: 'avoid-all' };

            // Gerar o PDF.
            html2pdf().from(element).set({
                margin: [0.25, 0.25, 0.25, 0.25],
                filename: `${this.nomeArquivo}.pdf`,
                pagebreak: pagebreak,
                jsPDF: {orientation: 'portrait', unit: 'in', format: 'a4', compressPDF: true}
            })
            .toPdf()
            .get('pdf')
            .then(function (pdf) {
                var totalPages = pdf.internal.getNumberOfPages();

                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(8);
                    pdf.setTextColor(75);
                    pdf.text('Página ' + i + ' de ' + totalPages, (pdf.internal.pageSize.getWidth() / 2) - 0.4, (pdf.internal.pageSize.getHeight() - 0.2));
                }

                // Convertendo o PDF para um blob
                var pdfBlob = pdf.output('blob');

                // Criando uma URL de objeto para o blob
                var url = URL.createObjectURL(pdfBlob);

                // Abrindo o PDF em uma nova aba
                window.open(url, '_blank');
            })
            .catch(function (err) {
                showError(err)
            });
        },

        salvarExcel() {
            // Crie uma nova planilha
            const ws = XLSX.utils.json_to_sheet(this.relResults);

            // Crie um novo livro de trabalho e adicione a planilha
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Dados');

            // Converta o livro de trabalho para um arquivo Excel
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            // Salve o arquivo
            saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${this.nomeArquivo}.xlsx`);
        },

        async gerarBoletos(){  
            if(this.paramBoletos.conta < 1 || this.paramBoletos.convenio < 1) {

                showError('É necessário selecionar a conta bancária e convênio antes de gerar os boletos.')

            } else {                

                if(this.paramBoletos.parcelas.length < 1) {

                    showError('Selecione ao menos uma parcela.')

                } else {
                    this.overGerBoleto = true

                    axios['post'](`${baseApiUrl}/gerarboleto/emitir`, this.paramBoletos)
                    .then(() => {
                        this.overGerBoleto = false                        
                        showSuccess('Boletos gerados com sucesso.')
                        this.modalEmiteBoleto = false
                    })
                    .catch(err => {
                        this.overGerBoleto = false
                        showError(err)
                    })
                }
            }
            
        },
        gerarNFSe(f) {  
            
            if (f) {
                this.idsParcelas = []
                this.idsParcelas.push(f.id_conta_receber_parcela)
            }

            axios['post'](`${baseApiUrl}/gerarnfse`, {parcelas: this.idsParcelas})
                .then(() => {
                   showSuccess('Nota(as) emitidas com sucesso.')
                })
            .catch(err => {
                showError(err)
            })
        },
        carrgaUsuarioLogado(){
            const usu = JSON.parse(localStorage.getItem('__lancamentos_user'))
            this.contaReceber.usu_cad = usu.id
            this.contaReceber.usu_alt = usu.id
            this.contaReceber.id_filial = usu.idfilial
        },
        limiteCaracteres(value, q) {
             if(value) {return value.length <= q ? true : false } else {return true}
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadContasReceber()
        },
        loadContasReceber() { 
            const url = `${baseApiUrl}/consulta/cadastros/?tipo=contareceber&page=${this.page}` 
            
            axios.get(url, {
                params: {
                    consulta: this.consulta
                }
            })
            .then(res => {
                this.contasReceber = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            }) 
            .catch(err => {
                showError(err)
            }) 
        },

        relatorios() { 

        },

        desejaExcluirFatura() {
            if (this.faturasSelecionadas.length < 1) {
                showError('Selecine ao menos uma fatura para excluir.')
            } else {
                this.modalRemFatura = true
            }           
        },

        remove() {
            axios.delete(`${baseApiUrl}/contas_receber`, {
                params: {
                    idsFaturas: [ ...this.faturasSelecionadas ]
                }
            })
            .then(() => {
                showSuccess('Faturas excluidas com sucesso.')
                this.modalRemFatura = false
                this.reset()
            })
            .catch(err => {
                showError(err)
            })
        },

        loadParcelas(idContaRec) {
            const url = `${baseApiUrl}/contas_receber_parcelas/parcelas/${idContaRec}`

            axios.get(url).then(res => {
                this.parcelas = res.data
                this.parcelas.forEach((_, i) =>{
                    this.parcelas[i].vencimento = moment(this.parcelas[i].vencimento).format('YYYY-MM-DD')                        
                })
            })
            
        },        
        loadPessoas() {  
            const url = `${baseApiUrl}/pessoas`

            axios.get(url).then(res => {
                this.pessoas = res.data
            })
        },
        loadLancamentos() {
            let url = `${baseApiUrl}/lancamentos/consulta/`    
            url = url+`?data_inicio=1900-01-01`
            url = url+`&data_fim=2400-12-31`
            url = url+`&id_tipo=0`
            url = url+`&status=T`

            axios.get(url).then(res => {
                this.lancamentos = res.data.data.map(lancamento => {
                    return { ...lancamento,  value: lancamento.id_lancamento, text: lancamento.descricao}
                })
                this.count = res.data.count 
                this.limit = res.data.limit
            })  
        },
        onSelect(pessoa) {
            this.contaReceber.idpessoa = pessoa.idpessoa
        },                
        loadFormas() {
            const url = `${baseApiUrl}/formas_pagar_receber`
            axios.get(url).then(res => {
                this.formas = res.data.map(forma => {
                    return { ...forma, value: forma.id_forma_pagar_receber, text: `${forma.id_forma_pagar_receber} | ${forma.descricao}` }
                })
            })
        },          
        loadCondicoes() {
            const url = `${baseApiUrl}/condicoes_pagar_receber` 
            axios.get(url).then(res => {
                this.condicoes = res.data.map(condicao => {
                    return { ...condicao, value: condicao.id_condicao_pagar_receber, text: `${condicao.id_condicao_pagar_receber} | ${condicao.descricao}` }
                })
            })
        },                
        loadPlanoContas(exibeTodas) {
            const url = `${baseApiUrl}/plano_contas/?tipo=R`
            axios.get(url).then(res => {
                this.planoContas = res.data.map(planoConta => {
                    return { ...planoConta, value: planoConta.id_plano_conta, text: `${planoConta.id_plano_conta} | ${planoConta.descricao}` }
                })
                
                if(exibeTodas) this.planoContas.unshift({value: 0, text: 'Todas as Despesas' })
            })
        },
        gerarParcelas(){  
            const forma = this.formas.filter(obj =>{
                if ('id_forma_pagar_receber' in obj  && obj.id_forma_pagar_receber === this.contaReceber.id_forma_pagar_receber) {
                        return obj
                    } 
            })
            const cond = this.condicoes.filter(obj =>{
                if ('id_condicao_pagar_receber' in obj  && obj.id_condicao_pagar_receber === this.contaReceber.id_condicao_pagar_receber) {
                        return obj
                    } 
            })

            this.parcelas = [] 

            const vConta = parseFloat(this.contaReceber.valor).toFixed(2)  
            const qParc = cond[0].qtd_parcelas 
            const intv = cond[0].intervalo 
            
            //Parcelas
            const entr = cond[0].possui_entrada
            const vParc = parseFloat(vConta / qParc).toFixed(2) 
            const restop = parseFloat((vConta) - parseFloat((vParc*qParc)).toFixed(2) ).toFixed(2)  
            const ultParc = (Number(vParc) + Number(restop))
            
            //Taxas
            const coms = forma[0].comissao
            const taxa = forma[0].taxa
            const vTaxa = parseFloat((vConta * coms /100) + taxa).toFixed(2) 
            const vTaxap = parseFloat(vTaxa / qParc).toFixed(2) 
            const restot = parseFloat((vTaxa) - parseFloat((vTaxap*qParc)).toFixed(2) ).toFixed(2) 
            const ultTaxa = (Number(vTaxap) + Number(restot))

            for (let i = 0; i < qParc; i++){   

                const dias =   entr === 'S' ?  (i + 0) * intv : (i + 1) * intv

                this.parcelas.push({
                    "num_parc": i + 1 , 
                    "valor": (i+1 === qParc) ? parseFloat(ultParc).toFixed(2) : parseFloat(vParc).toFixed(2),
                    "saldo": (i+1 === qParc) ? parseFloat(ultParc).toFixed(2) : parseFloat(vParc).toFixed(2),
                    "taxa": (i+1 === qParc) ? parseFloat(ultTaxa).toFixed(2) : parseFloat(vTaxap).toFixed(2),
                    "vencimento": moment().add(dias, 'days').format('YYYY-MM-DD')               
                })                
            }

            if(this.fatura) {
                const p = [ ...this.parcelas ]
                this.$emit('enviaParcelas', p )
            }
        },
        reset() {
            this.mode = 'new'

            this.contaReceber = {
                idpessoa: undefined,
                id_pessoa: '',
                descricao: '',
                valor: 0.00,
                data_competencia: moment().format('YYYY-MM-DD'),
                data_documento: moment().format('YYYY-MM-DD'),
                status: 'A'
            }

            this.loadContasReceber()
            this.resetParcela()
            this.loadPlanoContas(true);
            this.idContRec = 0
            this.parcelas = []

            this.show = true
        },        
        resetParcela(){
            this.viewEdit = false
            this.modoParcela = 'Editar'
            this.variacaoParcela = 'warning'
            this.modeParcelas = 'save'
            this.parcela = {}
            this.index = -1
            this.botaoInativo = false
            this.enumeraParcelas()
        },
        enumeraParcelas() {
            this.parcelas.forEach((_, i) =>{                
                this.parcelas[i].num_parc = i + 1 
            })
        },
        validaParcelas() {
            const forma = this.formas.filter(obj =>{
                if ('id_forma_pagar_receber' in obj  && obj.id_forma_pagar_receber === this.contaReceber.id_forma_pagar_receber) {
                        return obj
                    } 
            })

            const vConta = parseFloat(this.contaReceber.valor).toFixed(2)  
            const taxa = forma[0].taxa
            const coms = forma[0].comissao

            const vTaxa = parseFloat((vConta * coms /100) + taxa).toFixed(2) 
            let somaParc = 0
            let somaTaxa = 0

            this.parcelas.forEach((el, i)=>{
                somaParc = somaParc + this.parcelas[i].valor
                somaTaxa = somaTaxa + this.parcelas[i].taxa
            })

            //alert(`Ooops...<br> Soma taxa: ${somaTaxa} | Soma parc: ${somaParc}`)

            if (Number(parseFloat(somaParc).toFixed(2)  ) !== Number(parseFloat(vConta).toFixed(2)  )) throw 'A soma das parcelas é diferente do valor da conta.'
            if (Number(parseFloat(vTaxa).toFixed(2)  ) !== Number(parseFloat(somaTaxa).toFixed(2)  )) throw 'A soma das taxas das parcelas não bate com o valor calculado.'            
        },
        save() {
            this.validaParcelas()            

            const method = this.contaReceber.id_conta_receber ? 'put' : 'post'
            const idcontareceber = this.contaReceber.id_conta_receber ? `/${this.contaReceber.id_conta_receber}` : '' 
          
            this.contaReceber.id_pessoa = this.contaReceber.idpessoa

            axios[method](`${baseApiUrl}/contas_receber${idcontareceber}`, this.contaReceber)
                .then((res) => {
                    this.idContRec = method === 'put' ? this.contaReceber.id_conta_receber : res.data.id_conta_receber[0] 

                    this.parcelas.forEach((_, i) =>{
                        this.parcelas[i].id_conta_receber = this.idContRec                        
                    })

                    // this.$emit('salvar-receita')

                    this.saveParcelas()
                    this.reset()
                })
            .catch(err => {
                showError(err)
            })
        },
        saveParcelas() {
            let method = this.contaReceber.id_conta_receber ? 'put' : 'post'
            let qParam = `/?idContaRec=${ this.idContRec }&valor=${ this.contaReceber.valor }`

            axios[method](`${baseApiUrl}/contas_receber_parcelas${qParam}`, this.parcelas)
                .then(() => {
                    this.$toasted.global.defaultSuccess()                    
                })
            .catch(showError)
        },
        saveParcela() {  
            if (this.index < 0) {
                this.parcelas.push({...this.parcela})
            } else {
                this.index = this.parcela.num_parc - 1
                this.parcelas.splice(this.index, 1, {...this.parcela})
            }   
            
            if(this.fatura) {
                const p = [ ...this.parcelas ]
                this.$emit('enviaParcelas', p )
            } 
                
            this.resetParcela()   
        },        
        removeParcela() {
            this.index = this.parcela.num_parc - 1
            this.parcelas.splice(this.index, 1)
            this.resetParcela()
        },
        loadContaReceber(contaReceber, mode = 'save') {

            this.mode = mode

            const url = `${baseApiUrl}/contas_receber/${contaReceber.id_conta_receber}`    

            axios.get(url).then(res => {
                this.contaReceber = { ...res.data }
                this.contaReceber.data_competencia = moment(this.contaReceber.data_competencia ).format('YYYY-MM-DD')   
                this.contaReceber.data_documento = moment(this.contaReceber.data_documento).format('YYYY-MM-DD') 
                this.contaReceber.idpessoa = this.contaReceber.id_pessoa
                this.contaReceber.id_pessoa = this.contaReceber.razao_social

                this.parcelas = [ ...this.contaReceber.parcelas ]
                this.parcelas.forEach((_, i) =>{
                    this.parcelas[i].vencimento = moment(this.parcelas[i].vencimento).format('YYYY-MM-DD')                        
                })

                this.loadPlanoContas(false),
                this.loadFormas(),
                this.loadCondicoes(),
                        
                this.show = !this.show
               
            }) 
            .catch(err => {
                showError(err)
                this.show = !this.show 
                this.showOver = !this.showOver 
            })
            
        },
        loadParcela(parcela, modeParcelas = 'save') {                       
            this.modeParcelas = modeParcelas
            
            if(this.modeParcelas === 'save') {
                this.modoParcela = 'Editar'
                this.variacaoParcela = 'warning' 
            } else {
                this.modoParcela = 'Excluir'
                this.variacaoParcela = 'danger' 
            }

            this.parcela = { ...parcela }                   
            this.index = this.parcela.num_parc - 1
                    
            setTimeout(()=> {this.botaoInativo = true}, 1)

            //alert('mais olha que benção!')
        },

        carregaFatura(f) { 
            this.contaReceber.valor = f.total_liquido
            this.contaReceber.id_forma_pagar_receber = f.id_forma_pagar_receber
            this.contaReceber.id_condicao_pagar_receber = f.id_condicao_pagar_receber 
            
            this.gerarParcelas()     
        },

        loadContaVenda(idContaRec) { 
            axios.get(`${baseApiUrl}/contas_receber/${idContaRec}`)
            .then((res) => {
                this.contaReceber = { ...res.data }
                this.contaReceber.data_competencia = moment(this.contaReceber.data_competencia ).format('YYYY-MM-DD')   
                this.contaReceber.data_documento = moment(this.contaReceber.data_documento).format('YYYY-MM-DD') 

                this.contaReceber.id_pessoa = res.data.razao_social

                this.loadParcelas(this.contaReceber.id_conta_receber)
            })  
            .catch(showError)    
        },

        calculaReceber() {
            if (this.liquidacao.saldoFatura >= this.liquidacao.saldoLiquidar ) {
                const total = parseFloat(this.liquidacao.saldoLiquidar || 0) + parseFloat(this.liquidacao.acrescimo || 0) - parseFloat(this.liquidacao.desconto || 0)

                this.liquidacao.totalLiquidacaoAbs = total
                this.liquidacao.totalReceber = total
                this.caixaMovimento.totalDinheiro = total
            } else {
                showError('O valor a receber não pode ser maior que o saldo da fatura.')
                this.liquidacao.totalLiquidacaoAbs = this.liquidacao.saldoFatura
                this.liquidacao.totalReceber = this.liquidacao.saldoFatura
                this.caixaMovimento.totalDinheiro = this.liquidacao.saldoFatura
            }   

            this.calculaDinheiro()        
            
        },

        salvarLiquidacao() {            
            const c = localStorage.getItem('__lancamentos_caixa') 
            const idCaixa = c ? JSON.parse(c).idCaixa : 0

            if (idCaixa > 0) {

                var data_1 = new Date(moment(JSON.parse(c).dataCaixa).format('yyyy-MM-DD hh:mm.ss'))
                var data_2 = new Date(moment(this.caixaMovimento.data_movimento).format('yyyy-MM-DD hh:mm.ss'))
    
                if (data_2 >= data_1){ 
    
                    this.caixaMovimento.id_caixa = idCaixa
                    this.caixaMovimento.totalGeral = this.liquidacao.totalLiquidacaoAbs
                    this.caixaMovimento.valor_recebido = this.liquidacao.totalReceber
                    this.caixaMovimento.valor_pago = this.liquidacao.totalPagar
                    this.caixaMovimento.historico = this.liquidacao.obs
    
                    let faturas = {
                        l: [],
                        n: {}
                    }

                    faturas.n = { ...this.caixaMovimento }
                    faturas.l.push({
                        id_parcela: this.faturaReceber.id_conta_receber_parcela,
                        data_recebimento: moment(this.caixaMovimento.data_movimento).format('yyyy-MM-DD hh:mm.ss'),
                        valor_saldo: Number(this.liquidacao.saldoLiquidar),
                        desconto: Number(this.liquidacao.desconto),
                        acrescimo: Number(this.liquidacao.acrescimo),
                        tipo: 'Receber'
                    })       
    
                    axios["post"](`${baseApiUrl}/caixa/liquidacao`, faturas)
                    .then(() => {
                        this.$toasted.global.defaultSuccess('Liquidação efetuada com sucesso.')
                        
                        this.loadContasReceber()
                        this.showModalLiquidar = false
                    })
                    .catch(err => {
                        showError(err)
                    })
    
                } else { this.$toasted.info('A data de liquidação não pode ser menor do que a data do caixa.'); return;} 

            } else {
                showError('É necessário estar logado e um caixa para poder liquidar uma fatura.')
            }           
            
        },

        loadCartaoCondicoes() {
            const url = `${baseApiUrl}/cartao_bandeiras/?admin=${this.cartao.id}`

            axios.get(url).then(res => {
                this.cartaoCondicoes = res.data.map(cartaoCondicao => {
                    return { ...cartaoCondicao, value: cartaoCondicao.id_cartao_bandeira, text: `${cartaoCondicao.id_cartao_bandeira} | ${cartaoCondicao.descricao}` }
                })
            })
        },

        gravarDeposito() {
            let totalCheques = this.caixaMovimento.totalCheques 
            let totalCartoes = this.caixaMovimento.totalCartoes 
            let totalDepositos = this.caixaMovimento.totalDepositos 

            if (this.depositoBancario.id_conta_bancaria === 0) { this.$toasted.global.defaultError({msg: 'Selecione a conta bancária.'}); return; }
            if (this.depositoBancario.valor <= 0) { this.$toasted.global.defaultError({msg: 'Valor deve ser maior que zero.'}); return; }  
           
           if ((totalDepositos + this.depositoBancario.valor) > (this.liquidacao.totalLiquidacaoAbs - totalCheques - totalCartoes) ) { 
                this.$toasted.global.defaultError({msg: 'Total de numerários maior que o valor da liquidação.'}) 
                return 
            }

            const c = this.contasBancarias.find(obj => {
                return obj.id_conta_bancaria === this.depositoBancario.id_conta_bancaria;
            });

            this.depositoBancario.nome_conta = c.text

            this.caixaMovimento.depositosBancario.push({ ...this.depositoBancario })
            
            this.calculaDepReceb() 
            this.calculaDinheiro()

            this.depositoBancario = {
                id_conta_bancaria: 0,
                valor: 0
            }
        },

        gravaCartao() {
            let totalCheques = this.caixaMovimento.totalCheques 
            let totalCartoes = this.caixaMovimento.totalCartoes 
            let totalDepositos = this.caixaMovimento.totalDepositos 

            if (this.cartao.qtde_parcelas < 1) { this.$toasted.global.defaultError({msg: 'Informe a quantidade de parcelas.'}); return; }
            if (this.cartao.id === 0) { this.$toasted.global.defaultError({msg: 'Escolha uma administradora de cartão.'}); return; }
            if (this.cartao.id_cartao_bandeira === 0) { this.$toasted.global.defaultError({msg: 'Escolha uma condição.'}); return; }
            if (this.cartao.valor <= 0) { this.$toasted.global.defaultError({msg: 'Valor deve ser maior que zero.'}); return; }
            if ((totalCartoes + this.cartao.valor) > (this.liquidacao.totalLiquidacaoAbs - totalCheques - totalDepositos ) ) { 
                this.$toasted.global.defaultError({msg: 'Total de numerários maior que o valor da liquidação.'}) 
                return 
            }

            const a = this.administradoras.find(obj => {
                return obj.id_cartao_adm === this.cartao.id;
            });

            const c = this.cartaoCondicoes.find(obj => {
                return obj.id_cartao_bandeira === this.cartao.id_cartao_bandeira;
            });

            if (!this.cartao.qtde_parcelas || this.cartao.qtde_parcelas < c.num_parc_min || this.cartao.qtde_parcelas > c.num_parc_max) { 
                this.$toasted.global.defaultError({msg: `Esta condição só permite quantidade de ${c.num_parc_min} até ${c.num_parc_max} parcelas.`}); return; 
            }

            this.cartao.descricao = `${a.descricao} (${c.descricao})`

            this.caixaMovimento.cartoes.push({ ...this.cartao })
            
            this.calculaCartao() 
            this.calculaDinheiro()

            this.cartao= {
                id: 0,                
                id_cartao_bandeira: 0,
                qtde_parcelas: 1,
                autorizacao: '',
                valor: 0,
                descricao: ''
            }
        },

        gravaCheque() {
            let totalCheques = this.caixaMovimento.totalCheques 
            let totalCartoes = this.caixaMovimento.totalCartoes 
            let totalDepositos = this.caixaMovimento.totalDepositos 

            if (this.cheque.num_cheque < 1) { this.$toasted.global.defaultError({msg: 'Informe numero do cheque.'}); return; }
            if (this.cheque.num_banco < 1) { this.$toasted.global.defaultError({msg: 'Informe numero do banco.'}); return; }
            if (this.cheque.agencia < 1) { this.$toasted.global.defaultError({msg: 'Informe a agencia.'}); return; }
            if (this.cheque.conta < 1) { this.$toasted.global.defaultError({msg: 'Informe a conta.'}); return; }
            if (this.cheque.valor <= 0) { this.$toasted.global.defaultError({msg: 'Valor deve ser maior que zero.'}); return; }
            
            if ((totalCheques + this.cheque.valor) > (this.liquidacao.totalLiquidacaoAbs - totalCartoes - totalDepositos ) ) { 
                this.$toasted.global.defaultError({msg: 'Total de numerários maior que o valor da liquidação.'}) 
                return 
            }

            this.cheque.descricao = `Nº ${this.cheque.num_cheque} (AG: ${this.cheque.agencia} CC: ${this.cheque.conta})`

            this.caixaMovimento.cheques.push({ ...this.cheque })
            
            this.calculaCheque() 
            this.calculaDinheiro()

            this.cheque= {
                id: 0,
                id_caixa_movimento: 0,
                num_cheque: '',
                agencia: '',
                conta: '',
                cpf_cnpj_emissor: '',
                nome_emissor: '',
                vencimento: moment().format('yyyy-MM-DD'),
                valor: 0,
            }
        },

        calculaCartao() {
            if (!this.caixaMovimento.cartoes.length) { 
                this.caixaMovimento.totalCartoes = 0
            } else {
                this.caixaMovimento.totalCartoes = 0 
                this.caixaMovimento.cartoes.forEach((_, i) =>{ 
                    this.caixaMovimento.cartoes[i].id = i + 1 
                    this.caixaMovimento.totalCartoes = this.caixaMovimento.totalCartoes + this.caixaMovimento.cartoes[i].valor
                })
            }
        },
        calculaCheque() {
            if (!this.caixaMovimento.cheques.length) { 
                this.caixaMovimento.totalCheques = 0
            } else {
                this.caixaMovimento.totalCheques = 0 
                this.caixaMovimento.cheques.forEach((_, i) =>{ 
                    this.caixaMovimento.cheques[i].id = i + 1 
                    this.caixaMovimento.totalCheques = this.caixaMovimento.totalCheques + this.caixaMovimento.cheques[i].valor
                })
            }
        },
        calculaDepReceb() {
            if (!this.caixaMovimento.depositosBancario.length) { 
                this.caixaMovimento.totalDepositos = 0
            } else {
                this.caixaMovimento.totalDepositos = 0 
                this.caixaMovimento.depositosBancario.forEach((_, i) =>{ 
                    this.caixaMovimento.depositosBancario[i].id = i + 1 
                    this.caixaMovimento.totalDepositos = this.caixaMovimento.totalDepositos + this.caixaMovimento.depositosBancario[i].valor
                })
            }
        },

        excluirDeposito(item) {
            const idx = this.caixaMovimento.depositosBancario.map((e)=>{ return e.id; }).indexOf(item.id);
            this.caixaMovimento.depositosBancario.splice(idx, 1)

            this.calculaDepReceb() 
            this.calculaDinheiro() 
        },
        excluirCheque(item) {
            const idx = this.caixaMovimento.cheques.map((e)=>{ return e.id; }).indexOf(item.id);
            this.caixaMovimento.cheques.splice(idx, 1)

            this.calculaCheque() 
            this.calculaDinheiro() 
        },
        excluirCartao(item) {
            const idx = this.caixaMovimento.cartoes.map((e)=>{ return e.id; }).indexOf(item.id);
            this.caixaMovimento.cartoes.splice(idx, 1)

            this.calculaCartao() 
            this.calculaDinheiro() 
        },

    },
    watch: {
        intervaloVenc() {
            this.consulta.dataIniVenc = this.intervaloVenc[0] ? moment(this.intervaloVenc[0]).format('YYYY-MM-DD') : null
            this.consulta.dataFimVenc = this.intervaloVenc[1] ? moment(this.intervaloVenc[1]).format('YYYY-MM-DD') : null
        },
        intervaloComp() {
            this.consulta.dataIniComp = this.intervaloComp[0] ? moment(this.intervaloComp[0]).format('YYYY-MM-DD') : null
            this.consulta.dataFimComp = this.intervaloComp[1] ? moment(this.intervaloComp[1]).format('YYYY-MM-DD') : null
        },
        gerarFatura() {            
            this.carregaFatura(this.fatura)            
        },
        loadFaturaVenda() {
            this.loadContaVenda(this.fatura.id_conta_receber)
        },
        resetFaturaVenda() {
            
        },
        'paramBoletos.conta': function() {
            this.loadConvenios()
            const result = this.contasBancarias.find((conta) => conta.id_conta_bancaria === this.paramBoletos.conta);
            this.paramBoletos.filialContaBancaria = result.id_filial
            
        },
        modalEmiteBoleto() {
            if (!this.modalEmiteBoleto) this.reset()
        },
        'cartao.id': function() {
            this.loadCartaoCondicoes()
        },

        'liquidacao.saldoLiquidar': function() {
            this.calculaReceber()
        },
        'liquidacao.acrescimo': function() {
            this.calculaReceber()
        },
        'liquidacao.desconto': function() {
            this.calculaReceber()
        },
    },
    mounted() {
        this.carrgaUsuarioLogado(),
        this.loadFiliais()
        this.loadPlanoContas(true)
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .entre-datas {
        margin: 0;
        font-weight: 600;
        font-size: 1rem;
    }    

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    } 

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    .frase-erro {
        color: crimson;
    }

    .parc-edit-remove {
        padding: 32px 20px 15px 0;
    }

    .moeda-tabela {
        display: flex;
        box-shadow: 0 0 0 0;
        border: 0 none;
        outline: 0;
        background: transparent;
        width: 100%;
        text-align: left;
    }

</style>